// @todo enable next eslint rules
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import { Accordion, AccordionItem } from '../../components/Accordion';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import {
  CommonPageSection,
  Content,
  ContentItemWrap,
  ContentItem,
  Sidebar,
  LinksBlock,
} from '../../components/CommonPageSection';
import CTA from '../../components/CTA';

import './index.scss';


/**
 * @todo escape special characters in jsx
 */
const FaqPage = () => (
  <Layout
    pageWrapperClassName="faq-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="F.A.Q. | Frequently Asked Questions | TakeAwayEssay.com"
      meta={[
        { name: 'description', content: '' },

        { name: 'robots', content: 'noindex,nofollow' },

        {
          property: 'og:title',
          content: 'F.A.Q. | Frequently Asked Questions | TakeAwayEssay.com',
        },
        { property: 'og:description', content: '' },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/frequently-asked-questions.html' },
      ]}
    />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="F.A.Q."
          />

          <ContentItem>
            <h1>
              Frequently Asked Questions
            </h1>

            <Accordion>
              <AccordionItem title="How does the TakeAwayEssay.com work?">
                <p>
                  Ordering a paper with TakeAwayEssay.com is an easy way to academic success. All
                  you need to
                  do is to fill in the basic order form, proceed with the payment and wait for
                  your assignment
                  to be completed within the deadline you choose.
                </p>
                <p>
                  If you are sure you can provide specific paper instructions or upload any of the
                  required
                  materials, you can start our ordering process by filling in the order form. Let
                  us take a
                  closer look at how our service works:
                </p>
                <ol>
                  <li>
                    <strong>
                      You fill in the order form and proceed with the payment.
                    </strong>
                    <br />
                    In case you have any questions while submitting your paper information into
                    the order form
                    just click on the 'info' sign after every field name and you will be able to
                    see a detailed
                    hint in the pop-up window on what exact information is required. After you
                    are&nbsp;through
                    with the order form you will be automatically provided with your
                    {' '}
                    <Link to="/dashboard/authorization/">
                      Personal order page
                    </Link>
                    {' '}
                    where you will be able to track the
                    progress of your order, upload any of the required materials and send messages
                    both
                    to&nbsp;the writer and to administrator.
                  </li>
                  <li>
                    <strong>
                      Personal writer is assigned to your order.
                    </strong>
                    <br />
                    Our qualified staff will choose the most appropriate writer who corresponds to
                    your field of
                    studies and order instructions. In case the writer must have any particular
                    software or
                    literature in order to get the assignment done, please do not forget to
                    mention this&nbsp;in
                    your initial instructions.
                  </li>
                  <li>
                    <strong>
                      Your paper is completed and delivered to your
                      {' '}
                      <Link to="/dashboard/authorization/">
                        Personal order page
                      </Link>
                      .
                    </strong>
                  </li>
                </ol>
                <p>
                  When the writer finishes your paper it is delivered to your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  {' '}
                  in a pdf preview version. The MS Word version of the order will be automatically
                  provided to you after you click on
                  'Approve' button in the 'Files' list&nbsp;of your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  . If any changes are to be applied to the paper, you are always welcome to
                  request a free revision with the new revision deadline for the writer (read more
                  &nbsp;about our
                  {' '}
                  <Link to="/revisions-policy.html">
                    revision
                    policy
                  </Link>
                  {' '}
                  here).
                  {' '}
                  <br />
                  Please click
                  {' '}
                  <Link to="/dashboard/orders/create/">
                    here
                  </Link>
                  {' '}
                  to Order now!
                </p>
              </AccordionItem>
              <AccordionItem title="Is your service confidential?">
                <p>
                  While placing an order with our company you will be asked to provide such
                  personal
                  information as your name, &nbsp;phone number and email address. Nevertheless,
                  please note that
                  using TakeAwayEssay.com is totally confidential&nbsp; and we do not distribute
                  your personal
                  information to the third parties. It is very important for you to know that
                  support team may
                  contact you using only particular phone number(s) stated on our web site, such
                  as
                  1-855-260-5455. In order to secure our mutual co-operation,
                  please do not
                  communicate with those who introduce themselves as&nbsp; TakeAwayEssay.com
                  support staff and
                  reach you from other phone numbers! Moreover, please be aware that our
                  representative will never ask you to provide your credit card information via
                  phone conversation, as it should be entered strictly during the checkout while
                  filling out payment systems' billing forms.&nbsp; TakeAwayEssay.com support
                  administrator will send a
                  confirmation letter
                  to your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  {' '}
                  whenever
                  your&nbsp; payment is received. For more information about our Privacy Policy
                  please check
                  {' '}
                  <Link to="/privacy-policy.html">
                    Privacy Policy
                  </Link>
                  {' '}
                  page.
                </p>
              </AccordionItem>
              <AccordionItem title="Is it ethical to receive your help for my work?">
                <p>
                  We offer original model papers that can be used legally in a number of ways, if
                  properly
                  referenced:
                </p>
                <ul>
                  <li>
                    As a source of arguments or ideas for your own research
                  </li>
                  <li>
                    As a source of additional understanding of the subject
                  </li>
                  <li>
                    Direct citing
                  </li>
                </ul>
                <p>
                  Nonetheless, check your college/university policies, including their definition
                  of plagiarism
                  and paraphrase before using our services. Make conscious decisions in regards to
                  your
                  education.
                </p>
              </AccordionItem>
              <AccordionItem title="Where are you located?">
                <p>
                  TakeAwayEssay.com is a Limassol-incorporated academic assistance company. We have
                  been working
                  with students from the United States and Canada since 2005
                  and are
                  accepting online orders from any other countries all over the world. Our support
                  office
                  facilities are located in Ukraine and Latvia.
                </p>
              </AccordionItem>
              <AccordionItem
                title="How can I pay for the custom written paper? Is it safe to submit payments through your site?"
              >
                <p>
                  TakeAwayEssay.com cooperates with reliable payment systems only to ensure safety
                  and speed of your money transfer. After you fill out the order form, you will be
                  redirected to the web page where hints and tips will guide you through an easy
                  process of completing the payment for our service. In only a couple of minutes we
                  receive the funds and start working on your order to give you a high quality
                  academic assistance.
                </p>
              </AccordionItem>
              <AccordionItem title="Is there a money back guarantee?">
                <p>
                  Please visit our
                  {' '}
                  <Link to="/money-back-guarantee.html">
                    Money Back Guarantee
                  </Link>
                  {' '}
                  web page for more information.
                </p>
              </AccordionItem>
              <AccordionItem title="Who will write my custom paper?">
                <p>
                  <strong>
                    What are the credentials of my writer? What is my writer's degree?
                  </strong>
                </p>
                <p>
                  Our professional writing staff consists of writers with various
                  academic degrees, from Master's to Ph. D level writers, arranged in a number of
                  specializations. Such arrangement allows us to individually choose a writer who
                  fits the exact&nbsp; requirements for writing a particular paper. To ensure the
                  quality of our service, we carefully investigate each writer's skills set, based
                  on the following parameters:
                </p>
                <ul>
                  <li>
                    Academic and professional experience;
                  </li>
                  <li>
                    Results of the writer's online written English test and writer's
                    application essay;
                  </li>
                  <li>
                    The score in a particular field of study that the writer has gained,
                    while working for DoMyPapers.com;
                  </li>
                  <li>
                    Writer's current availability and his willingness to complete a
                    particular paper for our customer.
                  </li>
                </ul>
                <p>
                  <strong>
                    Can I choose a specific writer?&nbsp;
                  </strong>
                </p>
                <p>
                  If you are a returning customer with our company, you have an opportunity
                  to request the writer who has previously completed an order for you. When
                  placing an order, you need to click on the “I want a specific writer” option and
                  choose that writer from the drop-down list. Make sure you do that in the way
                  indicated above, so that the writer you want gets assigned. If you fail to
                  request a specific writer using the button, there is no guarantee that your
                  particular writer will be assigned. &nbsp;
                </p>
                <p>
                  <strong>
                    Where is my writer from?
                  </strong>
                </p>
                <p>
                  We employ writers from all over the world, varying from English native
                  speakers&nbsp;(ENL) to non-native speakers with good command of English. All of
                  our writers demonstrate a sufficient command of English and a good understanding
                  of other subjects, so all customers can be sure to obtain the writing quality that
                  corresponds to their academic level, even if their paper is
                  completed by a non-native speaker.&nbsp;Each customer may choose, if possible,
                  out of:
                </p>
                <ul>
                  <li>
                    Best available writer;
                  </li>
                  <li>
                    Advanced (high quality);
                  </li>
                  <li>
                    ENL (US, CA).
                  </li>
                </ul>
                <p>
                  If the native speaking writer is currently not available for completing a paper
                  in any particular specialization, the paper can be completed by a non-native
                  speaking writer, whose knowledge of English still corresponds to the highest
                  TOEFL level mark.
                </p>
                <p>
                  <strong>
                    Can writers' category be changed?
                  </strong>
                </p>
                <p>
                  Our writers are being evaluated as often as possible to make sure we deliver
                  only top-notch orders. We as well frequently reshuffle the Advanced writers
                  to have the best ones on their team. Our real-time rating system takes
                  into account every order writer completes, selecting a true match for
                  such an elite team. Latest writer's skills evaluation or changing average
                  scores may be the reason why they spend some time on the regular team
                  and some time on the advanced team.
                </p>
                <p>
                  <strong>
                    Can I talk to the writer who will be writing my paper?
                  </strong>
                </p>
                <p>
                  You will be able to communicate with the writer by sending messages from your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  . You should also be getting an
                  email notification about every new message received from the
                  writer.&nbsp; Nevertheless, it is
                  your responsibility to check your personal page directly from time to time in
                  case&nbsp; writer has any questions regarding your order.
                </p>
              </AccordionItem>
              <AccordionItem title="Is there a possibility of plagiarism in completed order?">
                <p>
                  All of our papers are original. We have our own plagiarism detection system that
                  easily
                  detects any kind of Internet plagiarism. Unlike popular plagiarism detection
                  systems, which
                  are used by Universities (ex. Turnitin.com), we do not report to any public
                  databases,
                  therefore such checking is safe. Our company&nbsp;provides a
                  <span>
                    &nbsp;
                  </span>
                  <strong>
                    'plagiarism-free' guarantee
                  </strong>
                  <span>
                    &nbsp;
                  </span>
                  that ensures your paper to be checked for its
                  uniqueness. Please note that it is possible for a writing company to guarantee
                  no
                  plagiarism&nbsp;
                  <em>
                    against open Internet sources and a number of certain databases, but there
                    is no technology (except for&nbsp;
                    <a
                      href="http://turnitin.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      turnitin.com
                    </a>
                    &nbsp;itself) that
                    could guarantee no plagiarism against all sources that are indexed by turnitin
                  </em>
                  .
                  Unfortunately, we cannot guarantee that there is no paraphrased text in the
                  document you
                  receive. In order to be on the safe side, we suggest that you check the paper
                  using writecheck
                  service from&nbsp;
                  <a
                    href="http://turnitin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    turnitin.com
                  </a>
                  <em>
                    &nbsp;and
                    send us the report
                  </em>
                  .
                </p>
              </AccordionItem>
              <AccordionItem title="Do you keep a database of pre-written essays?">
                <p>
                  No, we do not have such a database. All papers you order from us are written
                  from
                  scratch.
                </p>
              </AccordionItem>
              <AccordionItem title="What is the format of your custom essays?">
                <p>
                  All our academic papers come in accepted academic format: 1-inch margins on all
                  sides, 275
                  words per page,&nbsp; formatted with 12 point Times New Roman/Arial font,
                  double-spaced. If
                  you need special formatting for your paper,&nbsp; please include these
                  requirements in the
                  "Paper instructions" box on the order page.
                </p>
              </AccordionItem>
              <AccordionItem title="When will my custom paper be done?">
                <p>
                  When you place an order with TakeAwayEssay.com, you can choose from a great
                  variety of
                  delivery options and in this way determine your deadline for the paper to be
                  completed. The
                  time is calculated automatically from the moment we receive the payment in our
                  system. For
                  example, if you pay for an order at 6 P.M. on Monday and choose a 3 days
                  delivery option, you
                  will receive your custom paper by 6 P.M. on Thursday.
                </p>
                <p>
                  To ensure the quality of your paper and conduct the in-depth research, the
                  writer requires
                  sufficient amount of time. Therefore, please choose the most suitable deadline
                  when you place
                  an order.
                </p>
                <p>
                  Make sure that you upload all the additional materials at the beginning. If you
                  fail to do
                  that, the deadline for your order will be postponed as well. If you choose our
                  Progressive
                  Delivery service, you will receive an individual delivery schedule that will be
                  followed&nbsp;up by the writer assigned to your project.
                </p>
                <p>
                  The Final Submission deadline is the exact date when you have to submit your
                  assignment.
                  Please make sure that your Final Submission Deadline exceeds your First Draft
                  Deadline by at
                  least 30%, so that there is enough time for any possible revisions of your
                  order.
                </p>
              </AccordionItem>
              <AccordionItem title="What is Progressive Delivery service and how does it work?">
                <p>
                  Progressive Delivery service is a unique and reliable feature, designed
                  specifically for
                  aiding extended orders. Such academic works as Theses, Dissertations, Capstone
                  Projects, etc.,
                  can be delivered to you in parts, as the writer progresses. This feature costs
                  only 10% extra
                  to your order price. So, if you want to pay for your order in parts, you can
                  easily make use
                  of Progressive Delivery feature by contacting our operators, just before
                  completing the order
                  form, to arrange a payment plan for you.
                </p>
                <ol>
                  <li>
                    Pay for your order in segments;
                  </li>
                  <li>
                    Maintain closer communication with your writer (should you so desire);
                  </li>
                  <li>
                    Receive the Thesis/Dissertation part by part or even Chapter by Chapter;
                  </li>
                  <li>
                    Plan and schedule your academic work in advance.
                  </li>
                </ol>
                <p>
                  Progressive Delivery works according to this general outline:
                </p>
                <ol>
                  <li>
                    You place an order and we set up a Progressive Delivery and payment plan for
                    you.
                  </li>
                  <li>
                    We assign the most qualified writer to start working on your order.
                  </li>
                  <li>
                    The writer delivers each part of the order according to the PD schedule.
                  </li>
                  <li>
                    You review and comment on each part after it is completed by the writer.
                  </li>
                  <li>
                    Each part can be revised according to your comments while the writer continues
                    working on
                    the next part of your order.
                  </li>
                  <li>
                    You receive a fully completed paper that requires minimal or no revisions.
                  </li>
                </ol>
              </AccordionItem>
              <AccordionItem title="How do I fill in the order/inquiry form?">
                <p>
                  Please go to the order form. Once there, please specify your topic, page count
                  (excluding the
                  title and the bibliography page),&nbsp; delivery date, and the number of
                  sources. Be sure to
                  leave your comments on what exactly your assignment requirements are. In case
                  you have any
                  questions while submitting your paper information into the order form, just
                  click on the
                  'info' sign after every field name and you will see a detailed hint in the
                  pop-up window. You
                  also need to indicate the course title, writing style, and any other important
                  details. Your
                  comments are important to our writers for their future work on your assignment.
                </p>
                <p>
                  You can also place a free inquiry if you have complicated instructions for the
                  order and want
                  to double check if we are able to complete it.&nbsp; We will send you the
                  payment link only
                  after an appropriate writer is found.
                </p>
              </AccordionItem>
              <AccordionItem
                title="How will I be able to send my case study/articles/materials to the
                    writer?"
              >
                <p>
                  After you are through with the order form you will be automatically provided
                  with your
                  {' '}
                  <Link
                    to="/dashboard/authorization/"
                  >
                    Personal order page
                  </Link>
                  {' '}
                  at&nbsp; TakeAwayEssay.com. Any of
                  the required case studies/articles/materials can be attached directly to the
                  'Files'&nbsp; list of your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  {' '}
                  if you
                  have previously selected this option in the order form. As soon as the
                  materials&nbsp; are
                  uploaded in that list they become visible for the writer. If you experience any
                  difficulties
                  with uploading your files,&nbsp; feel free to contact our operator and ask for
                  assistance.
                </p>
              </AccordionItem>
              <AccordionItem title="What is my personal order page? How does it work?">
                <p>
                  '
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  ' is your customer's page where
                  you can track the progress of your order, upload any of the required materials
                  and communicate
                  both with the writer and administrator. For your convenience each
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  {' '}
                  contains:
                </p>
                <ol>
                  <li>
                    'Your Orders' tab which helps you track your orders history with
                    TakeAwayEssay.com and
                    displays the list of all your current and finished/canceled projects;
                  </li>
                  <li>
                    'New Order' tab where you can submit your new paper request;
                  </li>
                  <li>
                    'Profile' section which contains your personal information and gives an option
                    to change
                    your password.
                  </li>
                </ol>
                <p>
                  Inside each of your orders placed with TakeAwayEssay.com you will also be able
                  to check your
                  'Order Info' and use 'Messages' tab to communicate with your writer and
                  administrator.
                </p>
              </AccordionItem>
              <AccordionItem title="What if I forgot my password?">
                <p>
                  When placing your first order with TakeAwayEssay.com, you will be asked to
                  create your
                  individual password. If you have forgotten your personal password while
                  submitting a new order
                  form, please follow the link and it redirect you to the page with guidelines for
                  further
                  actions.
                </p>
              </AccordionItem>
              <AccordionItem title="How will I receive my paper?">
                <p>
                  As soon as the writer completes your paper, you should receive a notification
                  email with the
                  link to your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  . However, when the
                  deadline approaches you should also check your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal
                    order page
                  </Link>
                  {' '}
                  directly in order to avoid any kind of&nbsp; misunderstandings. Please note
                  that we do not send files by email. The only way you can download and review
                  your custom
                  paper&nbsp; is by following the link to your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal
                    order page
                  </Link>
                  {' '}
                  or clicking on 'Manage your orders' from our web site.
                </p>
              </AccordionItem>
              <AccordionItem title="How do I download printable Microsoft Word version?">
                <p>
                  You will be able to download a printable Microsoft Word file after you approve
                  the PDF
                  version. The file will be located in the 'Files'&nbsp; list of your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order
                    page
                  </Link>
                  {' '}
                  and will have a special mark (!!Ms Word version of the paper!!).
                </p>
              </AccordionItem>
              <AccordionItem
                title="I haven`t received my custom paper by the deadline. What should I
                    do?"
              >
                <p>
                  Contact us immediately. You can either write a message on your order page, or
                  enter
                  live-chat, or call us.&nbsp; Our operator will carefully examine your order and
                  will assist
                  you with it right away. The reasons may vary from time to time.&nbsp; It can
                  happen that you
                  have entered a wrong email address or your SPAM filter does not allow you to
                  receive emails
                  from us. It&nbsp; is also possible that the writer is waiting for your reply and
                  can not
                  continue working without your assistance, or we are checking&nbsp; the work for
                  plagiarism.
                  Contact us in the first place if you have any problem with getting the order on
                  time.
                </p>
              </AccordionItem>
              <AccordionItem title="What if I do not like my custom paper?">
                <p>
                  In case your assignment requires some changes, you are welcome to request a
                  {' '}
                  <Link
                    to="/revisions-policy.html"
                  >
                    revision
                  </Link>
                  {' '}
                  from the 'Files'&nbsp; list of your
                  {' '}
                  <Link to="/dashboard/authorization/">
                    Personal order page
                  </Link>
                  {' '}
                  by sending your comments to the
                  writer and stating the exact number of hours they can use to complete it. If you
                  require
                  significant alterations to the original order, we will charge a re-writing fee
                  that&nbsp; is
                  calculated depending on the complexity of the task. Please check our
                  {' '}
                  <Link to="/revisions-policy.html">
                    revision policy
                  </Link>
                  {' '}
                  for more information.
                </p>
              </AccordionItem>
              <AccordionItem title="What is the samples service?">
                <p>
                  Samples are the three randomly generated pages from the orders, that were
                  previously
                  completed by the assigned Writer. This service allows you to get acquainted with
                  the writing
                  style of the person, who is going to work on your order. It also gives you an
                  opportunity to
                  change the Writer within 2 hours from the moment samples are generated. The
                  Writer may be
                  changed up to three times on one order.
                  <br />
                  If you fail to request a different Writer within the given time, we regard it as
                  your
                  acceptance of the Writer who was assigned initially. The service is available
                  for $5.
                </p>
              </AccordionItem>
              <AccordionItem
                title="Do your writers complete online assignments and multiple choice (check
                    boxes, T/F, etc.) tests?"
              >
                <p>
                  We do not complete multiple choice tests and/or any other kind of online tests,
                  as we believe
                  it is as much the student's direct responsibility to pass tests (whether they
                  are online or
                  not), as it is to take exams. In addition to that, it is hard to evaluate the
                  true quantity of
                  work done by the writer while performing a multiple choice test. Moreover,
                  please be advised
                  not to share your personal information with the writers (including any accounts
                  login
                  information), in the paper details and through messages, as the company will not
                  take
                  responsibility if your personal data is abused in such a situation. In case you
                  have ordered
                  such an assignment, your order will be cancelled and money will be refunded.
                </p>
              </AccordionItem>
              <AccordionItem title="What is Complex Assignment?">
                <p>
                  A complex assignment indicates an order that requires special knowledge and more
                  advanced
                  research skills. Consequently, writers with such qualities should be compensated
                  accordingly.
                  Here is a list of disciplines the service 'complex assignment' applies to:
                </p>
                <ul>
                  <li>
                    Accounting
                  </li>
                  <li>
                    Architecture, Building and Planning
                  </li>
                  <li>
                    Chemistry
                  </li>
                  <li>
                    Computer science
                  </li>
                  <li>
                    Finance
                  </li>
                  <li>
                    Mathematics
                  </li>
                  <li>
                    Physics
                  </li>
                  <li>
                    Statistics
                  </li>
                  <li>
                    IT, Web
                  </li>
                  <li>
                    Biology (and other Life Sciences)
                  </li>
                  <li>
                    Civil Engineering
                  </li>
                  <li>
                    Engineering
                  </li>
                </ul>
                <p>
                  If you place an order for one of these disciplines, you will be charged 20% more
                  than for
                  ordinary assignments.
                </p>
                <p>
                  Orders that fall into the category of 'Other' are reviewed by the Support Team,
                  and if they
                  identify your discipline as a complex one, you will be charged an additional 20%
                  of the
                  initial order price. You will be notified of the price change and receive a link
                  for
                  additional payment.
                </p>
              </AccordionItem>
            </Accordion>

          </ContentItem>
        </ContentItemWrap>
      </Content>
      <Sidebar>
        <LinksBlock />
      </Sidebar>
    </CommonPageSection>

    <Calculator />

    <CTA isOnlyWaves />

  </Layout>
);

export default FaqPage;
